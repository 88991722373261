const se = {
  Rapporter: 'Rapporter',
  Rapport: 'Rapport',
  ADMIN: 'Administratör',
  USER: 'Användare',
  Tillbaka: 'Tillbaka',
  Nästa: 'Nästa',
  'av val': 'av',
  'rad(er)': 'rad(er)',
  Sida: 'Sida',
  Sök: 'Sök',
  'Välj ansökan': 'Välj ansökan',
  'Ansökan (om ej i listan, vänligen lämna tomt)':
    'Ansökan (om ej i listan, vänligen lämna tomt)',
  'Ansökan ej länkad': 'Ansökan ej länkad',
  'Inga resultat': 'Inga resultat',
  'Inga tidigare ansökningar': 'Inga tidigare ansökningar',
  'Inga tidigare rapporter': 'Inga tidigare rapporter',
  'Kopiera länk': 'Kopiera länk',
  'Kopiera ansökningsnummer': 'Kopiera ansökningsnummer',
  'Öppna ansökan': 'Öppna ansökan',
  'Öppna rapport': 'Öppna rapport',
  Från: 'Från',
  'Öppna meddelande': 'Öppna meddelande',
  accountType: 'Kontotyp',
  role: 'Roll',
  emailVerified: 'Mejladress verifierad',
  senderType: 'Avsändartyp',
  topic: 'Ämne',
  read: 'Läst',
  deletedAt: 'Raderad',
  adminId: 'Admin ID',
  inquirerName: 'Avsändarens namn',
  inquirerEmail: 'Avsändarens email',
  inquirerSenderType: 'Avsändarens typ',
  inquirerTopic: 'Ämne',
  inquirerMessage: 'Meddelande',
  adminImage: 'Admin profilbild',
  adminEmail: 'Admin email',
  inquiryId: 'Meddelande ID',
  image: 'Bild',
  enabled: 'Aktiverad',
  Fråga: 'Fråga',
  Mottagare: 'Mottagare',
  Avsändare: 'Avsändare',
  'Svar från': 'Svar från',
  'Öppna användare': 'Öppna användare',
  Kolumner: 'Kolumner',
  valda: 'valda',
  app: 'app',
  user: `användare`,
  musician: 'musiker',
  researcher: 'forskare',
  organization: 'organisation',
  other: 'annan',
  email_address: 'mejladress',
  account_type: 'kontotyp',
  'Logga ut': 'Logga ut',
  yes: 'ja',
  no: 'nej',
  open: 'öppna',
  '404': 'Sidan hittades inte',
  '404_text':
    'Tyvärr kunde vi inte hitta sidan du efterfrågade. Kanske är adresses felstavad?',
  'Våra anslag': 'Våra anslag',
  go_to_home: 'Gå till startsidan',
  sign_in_title: 'Logga in till KC-Fonden',
  list: `Lista`,
  create_user: 'skapa användare',
  edit: `redigera`,
  shop: `handla`,
  blog: `blogg`,
  post: `inlägg`,
  mail: `meddelande`,
  chat: `chat`,
  cards: `kort`,
  posts: `inlägg`,
  create: `ny ansökan`,
  history: `Tidigare ansökningar`,
  previous_reports: `Tidigare rapporter`,
  new_report: `ny rapport`,
  report: `rapport`,
  reportId: `Rapport`,
  'Ej länkad': 'Ej länkad',
  kanban: `kanban`,
  general: `allmän`,
  banking: `bank`,
  booking: `bokning`,
  profile: `profil`,
  account: `konto`,
  product: `produkt`,
  invoice: `kvitto`,
  details: `detaljer`,
  checkout: `kassa`,
  calendar: `kalender`,
  analytics: `Analys`,
  ecommerce: `e-handel`,
  management: `Hantering`,
  message: 'meddelande',
  messages: 'Meddelanden',
  inbox: 'Inkorg',
  sent: 'Skickat',
  mode: 'Ljust/Mörkt',
  'Ljust/Mörkt': 'Ljust/Mörkt',
  presets: 'Färger',
  settings: 'Inställningar',
  fullscreen: 'Helskärm',
  fullscreen_off: 'Avluta helskärm',
  menu_level: `menynivå`,
  menu_level_2a: `menynivå 2a`,
  menu_level_2b: `menynivå 2b`,
  menu_level_3a: `menynivå 3a`,
  menu_level_3b: `menynivå 3b`,
  menu_level_4a: `menynivå 4a`,
  menu_level_4b: `menynivå 4b`,
  item_disabled: `enhet avstängd`,
  item_label: `etikett`,
  item_caption: `enhet undertext`,
  item_external_link: `enhet extern länk`,
  description: `beskrivning`,
  other_cases: `andra fall`,
  item_by_roles: `enheter per roll`,
  only_admin_can_see_this_item: `Endast administratörer kan se denna enhet`,
  admin_login: 'Logga in som admin',
  read_more: 'läs mer',
  spring: 'vår',
  summer: 'sommar',
  fall: 'höst',
  winter: 'vinter',
  'Sidan hittades inte': 'Sidan hittades inte',
  'Stiftelsen Kempe-Carlgrenska Fonden': 'Stiftelsen Kempe-Carlgrenska Fonden',
  'En allmännyttig stiftelse för projekt som rör barn & unga.':
    'En allmännyttig stiftelse för projekt som rör barn & unga.',
  'Bidrag är i första hand avsedda för att täcka direkta kostnader kopplade till projektets genomförande. Vi tillåter att en begränsad del av bidraget används för overheadkostnader (5-10%), men kräver då en detaljerad och tydlig redovisning av det totala bidragsbeloppet. Kostnader som inte är direkt kopplade till projektet, och som inte kan specificeras, kommer inte att godkännas.':
    'Bidrag är i första hand avsedda för att täcka direkta kostnader kopplade till projektets genomförande. Vi tillåter att en begränsad del av bidraget används för overheadkostnader (5-10%), men kräver då en detaljerad och tydlig redovisning av det totala bidragsbeloppet. Kostnader som inte är direkt kopplade till projektet, och som inte kan specificeras, kommer inte att godkännas.',
  Ansökningar: 'Ansökningar',
  'Ansökningar och rapporter': 'Ansökningar och rapporter',
  'Antal per sida': 'Antal per sida',
  'Om oss': 'Om oss',
  Kontakt: 'Kontakt',
  Skicka: 'Skicka',
  Exportera: 'Exportera',
  'Vad vi gör': 'Vad vi gör',
  Integritetspolicy: 'Integritetspolicy',
  Integritets: 'Integritets',
  Policy: 'Policy',
  'Läs om vilka data vi samlar in': 'Läs om vilka data vi samlar in',
  'och hur vi använder dem.': 'och hur vi använder dem.',
  'Giltighetsdatum: 2024-01-01': 'Giltighetsdatum: 2024-01-01',
  '1. Introduktion\n\nStiftelsen Kempe-Carlgrenska Fonden ("vi", "oss" eller "vår") förbinder sig att skydda din integritet och säkerheten för din personliga information. Denna integritetspolicy förklarar hur vi samlar in, använder, avslöjar och skyddar din personliga data i enlighet med gällande dataskyddslagar i Sverige.':
    '1. Introduktion\n\nStiftelsen Kempe-Carlgrenska Fonden ("vi", "oss" eller "vår") förbinder sig att skydda din integritet och säkerheten för din personliga information. Denna integritetspolicy förklarar hur vi samlar in, använder, avslöjar och skyddar din personliga data i enlighet med gällande dataskyddslagar i Sverige.',
  '2. Information som Vi samlar in\n\nVi kan samla in och behandla följande typer av personlig information:\n- Kontaktinformation: Inkluderar ditt namn, e-postadress, postadress och telefonnummer.\n- Donationsinformation: Vi kan samla in information relaterad till dina donationer, inklusive betalningsuppgifter.\n- Användningsdata för Webbplats: Vi kan samla in data om dina besök på vår webbplats, som IP-adress, webbläsartyp, besökta sidor, och datum och tid för ditt besök.\n- Annan Information: All annan information som du frivilligt tillhandahåller i din interaktion med oss.':
    '2. Information som Vi samlar in\n\nVi kan samla in och behandla följande typer av personlig information:\n- Kontaktinformation: Inkluderar ditt namn, e-postadress, postadress och telefonnummer.\n- Donationsinformation: Vi kan samla in information relaterad till dina donationer, inklusive betalningsuppgifter.\n- Användningsdata för Webbplats: Vi kan samla in data om dina besök på vår webbplats, som IP-adress, webbläsartyp, besökta sidor, och datum och tid för ditt besök.\n- Annan Information: All annan information som du frivilligt tillhandahåller i din interaktion med oss.',
  '3. Hur Vi Använder Din Information\n\nVi kan använda din personliga information för följande ändamål:\n- Att tillhandahålla information om våra verksamheter, evenemang och tjänster.\n- Att bearbeta donationer och erkänna ditt stöd.\n- Att svara på dina förfrågningar och begäranden.\n- Att förbättra vår webbplats och användarupplevelse.\n- Att följa lagliga skyldigheter.':
    '3. Hur Vi Använder Din Information\n\nVi kan använda din personliga information för följande ändamål:\n- Att tillhandahålla information om våra verksamheter, evenemang och tjänster.\n- Att bearbeta donationer och erkänna ditt stöd.\n- Att svara på dina förfrågningar och begäranden.\n- Att förbättra vår webbplats och användarupplevelse.\n- Att följa lagliga skyldigheter.',
  '4. Delning och Avslöjande av Data\n\nVi säljer, byter eller hyr inte ut din personliga information till tredje parter. Vi kan dock dela dina uppgifter med:\n- Tjänsteleverantörer: Vi kan dela din information med betrodda tjänsteleverantörer som assisterar oss i våra verksamheter och tjänster.\n- Lagliga Krav: Vi kan avslöja din information för att uppfylla lagliga skyldigheter eller skydda våra rättigheter, integritet, säkerhet eller egendom.':
    '4. Delning och Avslöjande av Data\n\nVi säljer, byter eller hyr inte ut din personliga information till tredje parter. Vi kan dock dela dina uppgifter med:\n- Tjänsteleverantörer: Vi kan dela din information med betrodda tjänsteleverantörer som assisterar oss i våra verksamheter och tjänster.\n- Lagliga Krav: Vi kan avslöja din information för att uppfylla lagliga skyldigheter eller skydda våra rättigheter, integritet, säkerhet eller egendom.',
  '5. Dina Rättigheter\n\nDu har rätt att:\n- Få tillgång till och rätta dina personuppgifter.\n- Återkalla ditt samtycke, där så är tillämpligt.\n- Begära radering av dina personuppgifter, i enlighet med lagliga krav.\n- Invända mot behandlingen av dina uppgifter under vissa omständigheter.':
    '5. Dina Rättigheter\n\nDu har rätt att:\n- Få tillgång till och rätta dina personuppgifter.\n- Återkalla ditt samtycke, där så är tillämpligt.\n- Begära radering av dina personuppgifter, i enlighet med lagliga krav.\n- Invända mot behandlingen av dina uppgifter under vissa omständigheter.',
  '6. Säkerhetsåtgärder\n\nVi har implementerat rimliga säkerhetsåtgärder för att skydda din personliga information från obehörig åtkomst, avslöjande, ändring eller förstörelse.':
    '6. Säkerhetsåtgärder\n\nVi har implementerat rimliga säkerhetsåtgärder för att skydda din personliga information från obehörig åtkomst, avslöjande, ändring eller förstörelse.',
  '7. Kontaktinformation\n\nOm du har några frågor eller funderingar angående vår Integritetspolicy eller dina personuppgifter, vänligen kontakta oss på via kontaktformuläret.':
    '7. Kontaktinformation\n\nOm du har några frågor eller funderingar angående vår Integritetspolicy eller dina personuppgifter, vänligen kontakta oss på via kontaktformuläret.',
  '8. Ändringar i Denna Policy\n\nVi kan uppdatera denna Integritetspolicy från tid till annan för att spegla förändringar i våra rutiner eller lagkrav. Eventuella uppdateringar kommer att publiceras på vår webbplats med angivande av det nya giltighetsdatumet.':
    '8. Ändringar i Denna Policy\n\nVi kan uppdatera denna Integritetspolicy från tid till annan för att spegla förändringar i våra rutiner eller lagkrav. Eventuella uppdateringar kommer att publiceras på vår webbplats med angivande av det nya giltighetsdatumet.',
  'Genom att använda våra tjänster och tillhandahålla din personliga information samtycker du till de metoder som beskrivs i denna Integritetspolicyoch godkänner behandlingen av dina uppgifter enligt dessa riktlinjer.\n\nStiftelsen Kempe-Carlgrenska Fonden\nHälsingegatan 31\n113 31, Stockholm':
    'Genom att använda våra tjänster och tillhandahålla din personliga information samtycker du till de metoder som beskrivs i denna Integritetspolicyoch godkänner behandlingen av dina uppgifter enligt dessa riktlinjer.\n\nStiftelsen Kempe-Carlgrenska Fonden\nHälsingegatan 31\n113 31, Stockholm',
  Om: 'Om',
  Stiftelsen: 'Stiftelsen',
  stiftelsen: 'stiftelsen',
  Kempe: 'Kempe',
  Carlgrenska: 'Carlgrenska',
  Fonden: 'Fonden',
  'Läs om Stiftelsens historia': 'Läs om Stiftelsens historia',
  'och grundare.': 'och grundare.',
  'Vem var Fanny?': 'Vem var Fanny?',
  'Fanny Carlgren f. Kempe (1887- 1973) erhöll efter sin far Frans Kempe (vd Mo och Domsjö) ett stort arv och förvaltandet av arvet betraktade hon alltid som en mycket ansvarsfull uppgift. Detta arv lade grunden till Stiftelsen Kempe-Carlgrenska Fonden som stiftades den 28:e december 1951.':
    'Fanny Carlgren f. Kempe (1887- 1973) erhöll efter sin far Frans Kempe (vd Mo och Domsjö) ett stort arv och förvaltandet av arvet betraktade hon alltid som en mycket ansvarsfull uppgift. Detta arv lade grunden till Stiftelsen Kempe-Carlgrenska Fonden som stiftades den 28:e december 1951.',
  'Fanny Carlgren var intresserad av allt som gällde barn och ungdomar och de problem som kunde uppstå, vare sig det angick en organisation som arbetade med dessa eller om det gällde individen själv. De sista åren av hennes liv väckte narkotikaproblemet bland unga hennes engagemang.':
    'Fanny Carlgren var intresserad av allt som gällde barn och ungdomar och de problem som kunde uppstå, vare sig det angick en organisation som arbetade med dessa eller om det gällde individen själv. De sista åren av hennes liv väckte narkotikaproblemet bland unga hennes engagemang.',
  'Efter startandet av föreningen ”Hem och skola” fick hon många framstående föreläsare till de offentliga debatter hon anordnade. Utbildning förblev ett av hennes huvudintressen och har erhållit ganska omfattande bidrag från stiftelsen både under Fanny Carlgrens ordförandetid och senare.':
    'Efter startandet av föreningen ”Hem och skola” fick hon många framstående föreläsare till de offentliga debatter hon anordnade. Utbildning förblev ett av hennes huvudintressen och har erhållit ganska omfattande bidrag från stiftelsen både under Fanny Carlgrens ordförandetid och senare.',
  'Eftersom Fanny Carlgren var djupt religiös stod alltid ”kyrkan i centrum” för henne, dock långt ifrån kritiklöst. Hon var även en av dem som hjälpte Manfred Björkqvist att kunna fullgöra sitt livsverk – Sigtunastiftelsen.':
    'Eftersom Fanny Carlgren var djupt religiös stod alltid ”kyrkan i centrum” för henne, dock långt ifrån kritiklöst. Hon var även en av dem som hjälpte Manfred Björkqvist att kunna fullgöra sitt livsverk – Sigtunastiftelsen.',
  'Fanny Carlgren var född till och förblev hela sitt liv en dotter av Norrland. Under hennes uppväxttid var Norrland Sveriges U-land och de frågor som rörde Norrland berörde henne alltid personligen.':
    'Fanny Carlgren var född till och förblev hela sitt liv en dotter av Norrland. Under hennes uppväxttid var Norrland Sveriges U-land och de frågor som berörde Norrland berörde henne alltid personligen.',
  'Vård av barn': 'Vård av barn',
  Vård: 'Vård',
  av: 'av',
  barn: 'barn',
  'Omvårdnad och stöd till barn med särskilda behov':
    'Omvårdnad och stöd till barn med särskilda behov',
  'Vilka kan söka och för vad?': 'Vilka kan söka och för vad?',
  'Bidragsansökningar från handikappsorganisationer för all slags omvårdnad och stöd till barn/ungdom med särskilda behov. Stiftelsen ger i huvudsak bidrag till ännu inte genomförda projekt.':
    'Bidragsansökningar från handikappsorganisationer för all slags omvårdnad och stöd till barn/ungdom med särskilda behov. Stiftelsen ger i huvudsak bidrag till ännu inte genomförda projekt.',
  'Observera att stiftelsen prioriterar pilotprojekt framför etablerad verksamhet. Det betyder att för föreningar/organisationer som redan fått bidrag från Stiftelsen Kempe-Carlgrenska Fonden kan möjligheten att få ytterligare vara begränsad.':
    'Observera att stiftelsen prioriterar pilotprojekt framför etablerad verksamhet. Det betyder att för föreningar/organisationer som redan fått bidrag från Stiftelsen Kempe-Carlgrenska Fonden kan möjligheten att få ytterligare vara begränsad.',
  'Från ändamålsparagrafen': 'Från ändamålsparagrafen',
  '"Att främja vård och uppfostran av barn"':
    '"Att främja vård och uppfostran av barn"',
  'Sök anslag': 'Sök anslag',
  'Sök bidrag': 'Sök bidrag',
  'Sök forskningsstipendium': 'Sök forskningsstipendium',
  'Lägg till signatur': 'Lägg till signatur',
  Vänligen: 'Vänligen',
  Ansökningsförfarande: 'Ansökningsförfarande',
  Ansökan: 'Ansökan',
  'Ansök under fliken Ansökningar ovan. Till ansökan tillfogas:':
    'Ansök under fliken Ansökningar ovan. Till ansökan tillfogas:',
  'Två referenter. Bägge referenter ska vara någon med god kännedom om projektet som står utanför organisationen.':
    'Två referenter. Bägge referenter ska vara någon med god kännedom om projektet som står utanför organisationen.',
  'Budget för projektet': 'Budget för projektet',
  'Årsredovisning. Observera att bifogad årsredovisning även ska ha specificerade resultat- och balansräkningar som lämnar väsentlig information och är transparent gällande verksamheten. En årsredovisning som t ex summerar alla kostnader till en post ger oss inte denna information. Om sökandes bifogade årsredovisning lämnar alltför knapphändig information kommer vi att se detta som en ofullständig ansökan som inte kan tas upp till behandling.':
    'Årsredovisning. Observera att bifogad årsredovisning även ska ha specificerade resultat- och balansräkningar som lämnar väsentlig information och är transparent gällande verksamheten. En årsredovisning som t ex summerar alla kostnader till en post ger oss inte denna information. Om sökandes bifogade årsredovisning lämnar alltför knapphändig information kommer vi att se detta som en ofullständig ansökan som inte kan tas upp till behandling.',
  Verksamhetsberättelse: 'Verksamhetsberättelse',
  Stadgar: 'Stadgar',
  'Det finns två ansökningsperioder, februari och augusti. Ansökan kan endast göras inom dessa perioder.':
    'Det finns två ansökningsperioder, februari och augusti. Ansökan kan endast göras inom dessa perioder.',
  'Du ansöker genom fliken “Ansökningar” här ovan.':
    'Du ansöker genom fliken “Ansökningar” här ovan.',
  'Observera att under februari sökmånad kan du söka för studier som tidigast genomförs i juli samma år, samt i augusti sökmånad kan du söka för studier/projekt som tidigast genomförs vårterminen året därpå.':
    'Observera att under februari sökmånad kan du söka för studier som tidigast genomförs i juli samma år, samt i augusti sökmånad kan du söka för studier/projekt som tidigast genomförs vårterminen året därpå.',
  'Det finns två ansökningsperioder, februari och augusti. Ansökan kan endast göras inom dessa perioder. Då behandlingstiden är flera månader kan projektet påbörjas tidigast i juli samma år (för ansökningar inskickade i februari) samt i januari påföljande år (för ansökningar inskickade i augusti).':
    'Det finns två ansökningsperioder, februari och augusti. Ansökan kan endast göras inom dessa perioder. Då behandlingstiden är flera månader kan projektet påbörjas tidigast i juli samma år (för ansökningar inskickade i februari) samt i januari påföljande år (för ansökningar inskickade i augusti).',
  'Du ansöker under fliken Ansökningar ovan. Utöver formuläret behövs följande dokument:':
    'Du ansöker under fliken Ansökningar ovan. Utöver formuläret behövs följande dokument:',
  Forskningsplan: 'Forskningsplan',
  'Rekommendationsbrev/Vitsord': 'Rekommendationsbrev/Vitsord',
  'Intyg om institutionstillhörighet': 'Intyg om institutionstillhörighet',
  // slut ny
  'Det finns två ansökningsperioder, februari och augusti. Ansökan kan endast göras inom dessa perioder. Du kan ansöka via fliken Ansökningar ovan.':
    'Det finns två ansökningsperioder, februari och augusti. Ansökan kan endast göras inom dessa perioder. Du kan ansöka via fliken Ansökningar ovan.',
  'Ladda ner mall för intyg': 'Ladda ner mall för intyg',
  'Ladda ner exempel för Årsredovisning':
    'Ladda ner exempel för Årsredovisning',
  Utbildning: 'Utbildning',
  'Utveckling av utbildning': 'Utveckling av utbildning',
  'Bidragsansökningar från organisationer/skolor för projekt som rör utbildning för barn och unga.':
    'Bidragsansökningar från organisationer/skolor för projekt som rör utbildning för barn och unga.',
  '"Att lämna understöd för beredande av undervisning eller utbildning"':
    '"Att lämna understöd för beredande av undervisning eller utbildning"',
  Integrationsarbete: 'Integrationsarbete',
  'barn & ungdomar': 'barn & ungdomar',
  'Stipendium ur Gösta Vestlunds Fond': 'Stipendium ur Gösta Vestlunds Fond',
  'Varje år delas 30 000 kr ut till en förening, verksamhet eller ungdomsledare som på ett för styrelsen föredömligt vis arbetar med integration av unga människor.':
    'Varje år delas 30 000 kr ut till en förening, verksamhet eller ungdomsledare som på ett för styrelsen föredömligt vis arbetar med integration av unga människor.',
  Hjälpbehövande: 'Hjälpbehövande',
  Hjälp: 'Hjälp',
  be: 'be',
  hövande: 'hövande',
  'Övrigt.': 'Övrigt.',
  'Stiftelsen fullföljer ändamålsbestämmelsen i denna del genom att själv utse årligt bidrag till ett av oss bestämt projekt i Asien, Afrika, Baltikum eller Sverige.':
    'Stiftelsen fullföljer ändamålsbestämmelsen i denna del genom att själv utse årligt bidrag till ett av oss bestämt projekt i Asien, Afrika, Baltikum eller Sverige.',
  '"Att utöva hjälpverksamhet bland behövande"':
    '"Att utöva hjälpverksamhet bland behövande"',
  'Privatpersoner såväl som organisationer kan inte själva ansöka om bidrag för ändamålet hjälpbehövande. Det finns därför inget ansökningsformulär.':
    'Privatpersoner såväl som organisationer kan inte själva ansöka om bidrag för ändamålet hjälpbehövande. Det finns därför inget ansökningsformulär.',
  Forskning: 'Forskning',
  'Forskning gällande barn och ungdom.': 'Forskning gällande barn och ungdom.',
  'Ansökningar från doktorander/post doc/forskare vars arbete är på hög vetenskaplig nivå och gällande barn eller ungdoms psykosociala situation och skolgång.':
    'Ansökningar från doktorander/post doc/forskare vars arbete är på hög vetenskaplig nivå och gällande barn eller ungdoms psykosociala situation och skolgång.',
  'Frågor och svar': 'Frågor och svar',
  'Hur stort stipendiebelopp kan jag söka?':
    'Hur stort stipendiebelopp kan jag söka?',
  'Vi ger doktorandstipendier om 18 000 kr/månad och Post-doc-stipendier om 25 000 kr/månad.':
    'Vi ger doktorandstipendier om 18 000 kr/månad och Post-doc-stipendier om 25 000 kr/månad.',
  'Kan jag som forskare söka forskningsanslag?':
    'Kan jag som forskare söka forskningsanslag?',
  'Vi delar i första hand ut stipendier till doktorander. Ibland kan vi bevilja forskningsanslag. Projektledaren dokumentera sina meriter genom ett vidimerat cv och institutionens administrative chef/motsvarande, intyga att projektet bedrivs vid den aktuella institutionen.':
    'Vi delar i första hand ut stipendier till doktorander. Ibland kan vi bevilja forskningsanslag. Projektledaren dokumentera sina meriter genom ett vidimerat cv och institutionens administrative chef/motsvarande, intyga att projektet bedrivs vid den aktuella institutionen.',
  'Bidrag är i första hand avsedda för att täcka direkta kostnader kopplade till projektets genomförande. Vi tillåter att en begränsad del av bidraget används för overheadkostnader (5-10%), men kräver då en detaljerad och tydlig redovisning av det totala bidragsbeloppet. Kostnader som inte är direkt kopplade till projektet, och som inte kan specificeras, kommer inte att godkännas':
    'Bidrag är i första hand avsedda för att täcka direkta kostnader kopplade till projektets genomförande. Vi tillåter att en begränsad del av bidraget används för overheadkostnader (5-10%), men kräver då en detaljerad och tydlig redovisning av det totala bidragsbeloppet. Kostnader som inte är direkt kopplade till projektet, och som inte kan specificeras, kommer inte att godkännas',
  'Vad kan jag som doktorand söka för typ av stöd?':
    'Vad kan jag som doktorand söka för typ av stöd?',
  'Vi delar ut stipendier vilket är en skattefri gåva till utbildning/studier.':
    'Vi delar ut stipendier vilket är en skattefri gåva till utbildning/studier.',
  'Jag är doktorand och vill söka ert forskningsstipendium. Vilket konto ska jag ange?':
    'Jag är doktorand och vill söka ert forskningsstipendium. Vilket konto ska jag ange?',
  'Du ska ange ditt eget plusgiro/bankgiro. Finns inget sådant kan du ange ditt bankkontonummer. Det är viktigt att du som söker forskningsstipendium inte anger forskningsinstitutets bankgiro/plusgiro eftersom forskningsstipendierna inte täcker forskningsinstitutionernas s.k. overheadkostnader.':
    'Du ska ange ditt eget plusgiro/bankgiro. Finns inget sådant kan du ange ditt bankkontonummer. Det är viktigt att du som söker forskningsstipendium inte anger forskningsinstitutets bankgiro/plusgiro eftersom forskningsstipendierna inte täcker forskningsinstitutionernas s.k. overheadkostnader.',
  'Jag och forskningskollegor vill åka på en konferens. Kan jag söka bidrag för detta?':
    'Jag och forskningskollegor vill åka på en konferens. Kan jag söka bidrag för detta?',
  'Vi brukar ytterst sällan ge anslag till konferensresor.':
    'Vi brukar ytterst sällan ge anslag till konferensresor.',
  'Behöver forskningsstipendium respektive forskningsanslag redovisas?':
    'Behöver forskningsstipendium respektive forskningsanslag redovisas?',
  'Vi önskar kortfattad redogörelse för projektets resultat efter slutfört projekt. Redogörelsen görs här på hemsidan via vår ansökningplatform och är obligatorisk.':
    'Vi önskar kortfattad redogörelse för projektets resultat efter slutfört projekt. Redogörelsen görs här på hemsidan via vår ansökningplatform och är obligatorisk.',
  'Musikstuderande på högskolenivå': 'Musikstuderande på högskolenivå',
  Musikstuderande: 'Musikstuderande',
  på: 'på',
  högskolenivå: 'högskolenivå',
  'Våra anslag för musikstuderande på högskolenivå':
    'Våra anslag för musikstuderande på högskolenivå',
  Stipendier: 'Stipendier',
  'Musikstipendier delas endast ut för kommande termin, inte innevarande.':
    'Musikstipendier delas endast ut för kommande termin, inte innevarande.',
  'Utdelas normalt i storleksordningen 10 000 – 20 000 kronor.':
    'Utdelas normalt i storleksordningen 10 000 – 20 000 kronor.',
  'Ges i första hand till instrumentalister och sångare.':
    'Ges i första hand till instrumentalister och sångare.',
  'Ges inte retroaktivt, vilket innebär att redan avslutade studier inte beviljas medel.':
    'Ges inte retroaktivt, vilket innebär att redan avslutade studier inte beviljas medel.',
  'Kan eventuellt prioriteras för studenter som uttömt möjlighet att erhålla CSN medel.':
    'Kan eventuellt prioriteras för studenter som uttömt möjlighet att erhålla CSN medel.',
  'Personligt utformat brev med kort presentation över sökanden och dennes nuvarande studisituation (max 1 A4 sida).':
    'Personligt utformat brev med kort presentation över sökanden och dennes nuvarande studisituation (max 1 A4 sida).',
  'Om möjlighet att erhålla CSN medel har upphört bör sökanden styrka detta.':
    'Om möjlighet att erhålla CSN medel har upphört bör sökanden styrka detta.',
  'Ekonomisk redogörelse/budget gällande sökanden (max 1 A4 sida).':
    'Ekonomisk redogörelse/budget gällande sökanden (max 1 A4 sida).',
  'Två vidtalade refenter med telefonummer (se ansökningsformulär).':
    'Två vidtalade refenter med telefonummer (se ansökningsformulär).',
  'Vad menas med referenter?': 'Vad menas med referenter?',
  'Vi avser i första hand en lärare till sökanden.':
    'Vi avser i första hand en lärare till sökanden.',
  'Vad menas med ekonomisk redogörelse?':
    'Vad menas med ekonomisk redogörelse?',
  'Att sökanden redogör inkomster och utgifter. Vissa gör en månatlig uppställning andra sammanställer inkomster/utgifter för hela året. Bägge alternativen är godtagbara. Viktigt är att redovisa ev stipendium som under året erhållits från annat håll.':
    'Att sökanden redogör inkomster och utgifter. Vissa gör en månatlig uppställning andra sammanställer inkomster/utgifter för hela året. Bägge alternativen är godtagbara. Viktigt är att redovisa ev stipendium som under året erhållits från annat håll.',
  'Vi har ingen gräns för hur stort belopp som kan sökas men vi brukar ge stipendium i storleksordning 10 000 – 20 000 kr. Det händer att sökanden fått ett högre belopp än detta, oftast när de befinner sig längre fram i sin utbildning och kanske uttömt möjligheten till CSN lån.':
    'Vi har ingen gräns för hur stort belopp som kan sökas men vi brukar ge stipendium i storleksordning 10 000 – 20 000 kr. Det händer att sökanden fått ett högre belopp än detta, oftast när de befinner sig längre fram i sin utbildning och kanske uttömt möjligheten till CSN lån.',
  'Jag skulle behöva köpa ett bättre instrument. Kan jag söka bidrag för detta?':
    'Jag skulle behöva köpa ett bättre instrument. Kan jag söka bidrag för detta?',
  'Eftersom vi är en allmännyttig stiftelse ger vi stipendier för studier (utbildning= allmännyttigt ändamål).':
    'Eftersom vi är en allmännyttig stiftelse ger vi stipendier för studier (utbildning= allmännyttigt ändamål).',
  'Hur ska stipendiet redovisas?': 'Hur ska stipendiet redovisas?',
  'Kort redogörelse för studieresultat mm skall inges ett halvår efter erhållet stipendium. Redogörelsen görs här på hemsidan via vår ansökningplatform och är obligatorisk.':
    'Kort redogörelse för studieresultat mm skall inges ett halvår efter erhållet stipendium. Redogörelsen görs här på hemsidan via vår ansökningplatform och är obligatorisk.',
  Svenska: 'Svenska',
  'Det här är vår svenska sida': 'Det här är vår svenska sida',
  Hej: 'Hej',
  'Hej, välkommen tillbaka!': 'Hej, välkommen tillbaka!',
  'Logga in med': 'Logga in med',
  'Vänligen logga in med ett Google konto för att komma till vår ansökningsportal. \nFör närvarande kan du endast logga in med ett Google konto.':
    'Vänligen logga in med ett Google konto för att komma till vår ansökningsportal. \nFör närvarande kan du endast logga in med ett Google konto.',
  EDUCATION_DEVELOPMENT: 'Integrationsarbete för ungdom',
  UNIVERSITY_MUSIC_STUDENT: 'Musikstuderande på högskolenivå',
  CHILD_CARE_SUPPORT: 'Omvårdnad och stöd till barn med särskilda behov',
  YOUTH_INTEGRATION_WORK: 'Integrationsarbete för ungdom',
  CHILD_YOUTH_RESEARCH: 'Forskning gällande barn och ungdom',
  SUPPORT_NEEDY: 'Understöd till hjälpbehövande',
  OTHER_QUESTIONS: 'Övriga frågor',
  'Integrationsarbete för ungdom': 'Integrationsarbete för ungdom',
  'Forskning gällande barn och ungdom': 'Forskning gällande barn och ungdom',
  'Understöd till hjälpbehövande': 'Understöd till hjälpbehövande',
  'Övriga frågor': 'Övriga frågor',
  'Kontakta oss': 'Kontakta oss',
  Kontakta: 'Kontakta',
  Oss: 'Oss',
  'Skicka ett meddelande till Stiftelsen.':
    'Skicka ett meddelande till Stiftelsen.',
  Namn: 'Namn',
  'Jag är en...': 'Jag är en...',
  Ämne: 'Ämne',
  'Skicka meddelande': 'Skicka meddelande',
  'Tack! Vi har mottagit ditt meddelande':
    'Tack! Vi har mottagit ditt meddelande',
  'Något gick fel, kontrollera alla fält och försök igen':
    'Något gick fel, kontrollera alla fält och försök igen',
  'Meddelande Skickat!': 'Meddelande Skickat!',
  Svara: 'Svara',
  'Något gick fel!': 'Något gick fel!',
  'Din fråga:': 'Din fråga:',
  'Här är dina senaste ansökningar och deras status.':
    'Här är dina senaste ansökningar och deras status.',
  'Här finns alla ansökningar. Filtrera och sök för att hitta ansökningar.':
    'Här finns alla ansökningar. Filtrera och sök för att hitta ansökningar.',
  'Ansök om anslag': 'Ansök om anslag',
  'Redigera ansökan': 'Redigera ansökan',
  'Ansökningar kan redigeras till slutet av ansökningsfönstret.':
    'Ansökningar kan redigeras till slutet av ansökningsfönstret.',
  Status: 'Status',
  'Ansökt belopp': 'Ansökt belopp',
  'Ansökningsfönstret är stängt': 'Ansökningsfönstret är stängt',
  'Våra ansökningsfönster är 1 februari till 28 februari, och 1 augusti till 31 augusti varje år.':
    'Våra ansökningsfönster är 1 februari till 28 februari, och 1 augusti till 31 augusti varje år.',
  Datum: 'Datum',
  'Skapad den': 'Skapad den',
  'Inga ansökningar': 'Inga ansökningar',
  'Skicka in ansökan': 'Skicka in ansökan',
  'Vänligen fyll i alla fält för ditt konto':
    'Vänligen fyll i alla fält för ditt konto',
  'Saknar uppgifter': 'Saknar uppgifter',
  'Tack, vi har tagit emot din ansökan!':
    'Tack, vi har tagit emot din ansökan!',
  'Något gick fel, vänligen kontrollera all fält är korrekt ifyllda':
    'Något gick fel, vänligen kontrollera all fält är korrekt ifyllda',
  'Vänligen fyll in följande formulär för att ansöka om anslag.':
    'Vänligen fyll in följande formulär för att ansöka om anslag.',
  utkast: 'utkast',
  inlämnad: 'inlämnad',
  'under granskning': 'under granskning',
  'behöver mer info': 'behöver mer info',
  avslagen: 'avslagen',
  'beviljat anslag': 'beviljat anslag',
  DRAFT: 'utkast',
  SUBMITTED: 'inlämnad',
  UNDER_REVIEW: 'under granskning',
  NEEDS_MORE_INFO: 'behöver mer info',
  DECLINED: 'avslagen',
  GRANT_GIVEN: 'beviljat anslag',
  Email: 'Email',
  'Tilldelat belopp': 'Tilldelat belopp',
  email: 'email',
  applicationId: 'Ansökningsnummer',
  'Personnummer (tolv siffror)': 'Personnummer (tolv siffror)',
  Adress: 'Adress',
  Postnummer: 'Postnummer',
  Ort: 'Ort',
  'Belopp som söks från Stiftelsen Kempe-Carlgrenska Fonden (kronor)':
    'Belopp som söks från Stiftelsen Kempe-Carlgrenska Fonden (kronor)',
  'Plusgiro/bankgiro/bankkonto': 'Plusgiro/bankgiro/bankkonto',
  Kontohavare: 'Kontohavare',
  'Uppgifter om sökanden': 'Uppgifter om sökanden',
  'Uppgifter om stipendiet och utbildningen':
    'Uppgifter om stipendiet och utbildningen',
  'Finansiell information': 'Finansiell information',
  Referenter: 'Referenter',
  Bilagor: 'Bilagor',
  Instrument: 'Instrument',
  'Nuvarande utbildning': 'Nuvarande utbildning',
  'Sökt utbildning': 'Sökt utbildning',
  Utbildningssituation: 'Utbildningssituation',
  'Jag studerar redan på samma utbildning':
    'Jag studerar redan på samma utbildning',
  'Jag är ännu inte antagen': 'Jag är ännu inte antagen',
  'Jag har blivit antagen för en kommande utbildning':
    'Jag har blivit antagen för en kommande utbildning',
  'Högre musikutbildning vid Kungl. Musikhögskolan ':
    'Högre musikutbildning vid Kungl. Musikhögskolan ',
  'Går första året i masterprogrammet.': 'Går första året i masterprogrammet.',
  'Om du inte redan studerar, eller har blivit antagen på en utbildning måste du komplettera antagningsbesked i efterhand.':
    'Om du inte redan studerar, eller har blivit antagen på en utbildning måste du komplettera antagningsbesked i efterhand.',
  'Stipendiet kommer användas termin (höst/vår) och år':
    'Stipendiet kommer användas termin (höst/vår) och år',
  'Har du tidigare erhållit bidrag från Stiftelsen Kempe-Carlgrenska Fonden?':
    'Har du tidigare erhållit bidrag från Stiftelsen Kempe-Carlgrenska Fonden?',
  'Om ja, årtal (endast senaste anslag)':
    'Om ja, årtal (endast senaste anslag)',
  'Vidtalad referent 1: namn': 'Vidtalad referent 1: namn',
  'Vidtalad referent 1: telefonnummer': 'Vidtalad referent 1: telefonnummer',
  'Vidtalad referent 2: namn': 'Vidtalad referent 2: namn',
  'Vidtalad referent 2: telefonnummer': 'Vidtalad referent 2: telefonnummer',
  'Underskrivet och skannat intyg (PDF)':
    'Underskrivet och skannat intyg (PDF)',
  'Ekonomisk redogörelse (PDF)': 'Ekonomisk redogörelse (PDF)',
  'Personligt brev (PDF)': 'Personligt brev (PDF)',
  'Eventuellt styrkande av att CSN-medel ej kan erhållas (PDF)':
    'Eventuellt styrkande av att CSN-medel ej kan erhållas (PDF)',
  'Antagningsbesked eller intyg om studieplats (PDF)':
    'Antagningsbesked eller intyg om studieplats (PDF)',
  'Officiellt dokument från din utbildning som visar att du studerar eller kommer studera på den angivna utbildningen. Om du inte redan studerar på utbildningen, eller ännu inte har blivit antagen, kommer ett eventuellt stipendieanslag endast delas ut efter att antagningsbesked i efterhand kompletterats.':
    'Officiellt dokument från din utbildning som visar att du studerar eller kommer studera på den angivna utbildningen. Om du inte redan studerar på utbildningen, eller ännu inte har blivit antagen, kommer ett eventuellt stipendieanslag endast delas ut efter att antagningsbesked i efterhand kompletterats.',
  Sammanfattning: 'Sammanfattning',
  'Sammanfattning av sökandens presentation innehållande nuvarande studiesituation samt vad stipendiet ska användas till (max 3400 tecken, ungefär 500 ord)':
    'Sammanfattning av sökandens presentation innehållande nuvarande studiesituation samt vad stipendiet ska användas till (max 3400 tecken, ungefär 500 ord)',
  'Uppgifter om projectet och forskningsinstitutionen':
    'Uppgifter om projectet och forskningsinstitutionen',
  Forskarstatus: 'Forskarstatus',
  'Forskningen utförs vid institutionen för':
    'Forskningen utförs vid institutionen för',
  'Institutionens administrativa chef/ekonomiskt ansvarige: namn':
    'Institutionens administrativa chef/ekonomiskt ansvarige: namn',
  'Institutionens administrativa chef/ekonomiskt ansvarige: telefonnummer':
    'Institutionens administrativa chef/ekonomiskt ansvarige: telefonnummer',
  'Välj projektnivå': 'Välj projektnivå',
  'Välj årtal': 'Välj årtal',
  Projektnivå: 'Projektnivå',
  Projekttitel: 'Projekttitel',
  'Forskningsplan, specifikation av sökta medel och CV (PDF)':
    'Forskningsplan, specifikation av sökta medel och CV (PDF)',
  'Rekommendationsbrev/vitsord (PDF)': 'Rekommendationsbrev/vitsord (PDF)',
  'Intyg om institutionstillhörighet (PDF)':
    'Intyg om institutionstillhörighet (PDF)',
  'Sammanfattning av projektet, idé och syfte (max 3400 tecken, ungefär 500 ord)':
    'Sammanfattning av projektet, idé och syfte (max 3400 tecken, ungefär 500 ord)',
  Licentiatprojekt: 'Licentiate project',
  Doktorandprojekt: 'Doctoral project',
  'Post-doc-projekt': 'Post-doc project',
  'Projekt av hög vetenskaplig nivå': 'Project of high scientific level',
  LICENTIATE_PROJECT: 'Licentiatprojekt',
  DOCTORAL_PROJECT: 'Doktorandprojekt',
  POST_DOC_PROJECT: 'Post-doc-projekt',
  PROJECT_OF_HIGH_SCIENTIFIC_LEVEL: 'Projekt av hög vetenskaplig nivå',
  SPRING: 'vår',
  SUMMER: 'sommar',
  FALL: 'höst',
  WINTER: 'vinter',
  'Uppgifter om organisationen': 'Uppgifter om organisationen',
  'Uppgifter om projektet': 'Uppgifter om projektet',
  'Organisationens namn': 'Organisationens namn',
  'Namn/företrädare': 'Namn/företrädare',
  Organisationsnummer: 'Organisationsnummer',
  'Har organisationen tidigare erhållit bidrag från Stiftelsen Kempe-Carlgrenska Fonden?':
    'Har organisationen tidigare erhållit bidrag från Stiftelsen Kempe-Carlgrenska Fonden?',
  'Budget för projektet (PDF eller Excel)':
    'Budget för projektet (PDF eller Excel)',
  'Årsredovisning (balansräkning och resultaträkning) (PDF eller Excel)':
    'Årsredovisning (balansräkning och resultaträkning) (PDF eller Excel)',
  'Verksamhetsberättelse (PDF)': 'Verksamhetsberättelse (PDF)',
  'Stadgar (PDF)': 'Stadgar (PDF)',
  'Ja, vi har tagit del av informationen ovan':
    'Ja, vi har tagit del av informationen ovan',
  'Bifogad årsredovisning skall även ha specificerade resultat-och balansräkningar som lämnar väsentlig information och är transparent gällande verksamheten. En årsredovisning som tex summerar alla kostnader till en post ger för oss ingen sådan värdefull information. Om sökandes bifogade årsredovisning lämnar alltför knapphändig information kommer vi att se detta som en ofullständig ansökan som inte kan tas upp till behandling.':
    'Bifogad årsredovisning skall även ha specificerade resultat-och balansräkningar som lämnar väsentlig information och är transparent gällande verksamheten. En årsredovisning som tex summerar alla kostnader till en post ger för oss ingen sådan värdefull information. Om sökandes bifogade årsredovisning lämnar alltför knapphändig information kommer vi att se detta som en ofullständig ansökan som inte kan tas upp till behandling.',
  'Sammanfattning av projektet (max 3400 tecken, ungefär 500 ord)':
    'Sammanfattning av projektet (max 3400 tecken, ungefär 500 ord)',
  'Här är dina Tidigare rapporter.': 'Här är dina Tidigare rapporter.',
  'Ny rapport': 'Ny rapport',
  Redigera: 'Redigera',
  'Redigera rapport': 'Redigera rapport',
  'Inga rapporter': 'Inga rapporter',
  'Mål och resultat': 'Mål och resultat',
  'Tack, vi har tagit emot din rapport!':
    'Tack, vi har tagit emot din rapport!',
  'Skicka in rapport': 'Skicka in rapport',
  År: 'År',
  Termin: 'Termin',
  'Allmäna uppgifter': 'Allmäna uppgifter',
  'Vänligen beskriv de mål och resultat som uppnåtts.':
    'Vänligen beskriv de mål och resultat som uppnåtts.',
  'Bilaga (endast PDF), frivilligt': 'Bilaga (endast PDF), frivilligt',
  'Institutionens namn': 'Institutionens namn',
  Bakgrund: 'Bakgrund',
  Syfte: 'Syfte',
  Metod: 'Metod',
  Resultat: 'Resultat',
  'Redigera konto': 'Redigera konto',
  'Här kan du ändra eller lägga till information för ditt användarkonto.':
    'Här kan du ändra eller lägga till information för ditt användarkonto.',
  'Sparat!': 'Sparat!',
  'Något gick fel, kontrolla alla fält och försök igen':
    'Något gick fel, kontrolla alla fält och försök igen',
  Spara: 'Spara',
  'Ladda upp filer': 'Ladda upp filer',
  'Släpp eller välj fil': 'Släpp eller välj fil',
  'Släpp en fil här eljer välj en fil genom att klicka här':
    'Släpp en fil här eljer välj en fil genom att klicka här',
  användare: 'användare',
  musiker: 'musiker',
  forskare: 'forskare',
  organisation: 'organisation',
  MUSICIAN: 'Musiker',
  RESEARCHER: 'Forskare',
  ORGANIZATION: 'Organisation',
  OTHER: 'annan',
  annan: 'annan',
  mejladress: 'mejladress',
  kontotyp: 'kontotyp',
  ja: 'ja',
  nej: 'nej',
  Ja: 'Ja',
  Nej: 'Nej',
  'Ny användare': 'Ny användare',
  öppna: 'öppna',
  'Tyvärr kunde vi inte hitta sidan du efterfrågade. Kanske är adresses felstavad?':
    'Tyvärr kunde vi inte hitta sidan du efterfrågade. Kanske är adresses felstavad?',
  'Gå till startsidan': 'Gå till startsidan',
  'Logga in till KC-Fonden': 'Logga in till KC-Fonden',
  Lista: 'Lista',
  'skapa användare': 'skapa användare',
  redigera: 'redigera',
  handla: 'handla',
  blogg: 'blogg',
  inlägg: 'inlägg',
  meddelande: 'meddelande',
  kort: 'kort',
  'ny ansökan': 'ny ansökan',
  'Ny ansökan': 'Ny ansökan',
  'Tidigare ansökningar': 'Tidigare ansökningar',
  'Tidigare rapporter': 'Tidigare rapporter',
  'ny rapport': 'ny rapport',
  allmän: 'allmän',
  bank: 'bank',
  bokning: 'bokning',
  profil: 'profil',
  Konto: 'Konto',
  produkt: 'produkt',
  kvitto: 'kvitto',
  detaljer: 'detaljer',
  kassa: 'kassa',
  kalender: 'kalender',
  Analys: 'Analys',
  'e-handel': 'e-handel',
  Hantering: 'Hantering',
  Meddelanden: 'Meddelanden',
  Inkorg: 'Inkorg',
  Skickat: 'Skickat',
  Ljust: 'Ljust',
  Mörkt: 'Mörkt',
  Automatiskt: 'Automatiskt',
  Färger: 'Färger',
  Inställningar: 'Inställningar',
  Helskärm: 'Helskärm',
  'Avluta helskärm': 'Avluta helskärm',
  menynivå: 'menynivå',
  'menynivå 2a': 'menynivå 2a',
  'menynivå 2b': 'menynivå 2b',
  'menynivå 3a': 'menynivå 3a',
  'menynivå 3b': 'menynivå 3b',
  'menynivå 4a': 'menynivå 4a',
  'menynivå 4b': 'menynivå 4b',
  'enhet avstängd': 'enhet avstängd',
  etikett: 'etikett',
  'enhet undertext': 'enhet undertext',
  'enhet extern länk': 'enhet extern länk',
  beskrivning: 'beskrivning',
  'andra fall': 'andra fall',
  'enheter per roll': 'enheter per roll',
  'Endast administratörer kan se denna enhet':
    'Endast administratörer kan se denna enhet',
  'Logga in som admin': 'Logga in som admin',
  'läs mer': 'läs mer',
  vår: 'vår',
  sommar: 'sommar',
  höst: 'höst',
  vinter: 'vinter',
  'Ta Bort': 'Ta Bort',
  'Ta bort alla filer': 'Ta bort alla filer',
  'Fil Borttagen': 'Fil Borttagen',
  'Filen finns inte, eller så får du inte ta bort den':
    'Filen finns inte, eller så får du inte ta bort den',
  'spara alla filer': 'spara alla filer',
  Öppna: 'Öppna',
  'Fil nedladdad': 'Fil nedladdad',
  'Något gick fel, kunde inte hitta filen':
    'Något gick fel, kunde inte hitta filen',
  Hem: 'Hem',
  Anslag: 'Anslag',
  Studenter: 'Studenter',
  Musikstudenterande: 'Musikstudenterande',
  Forskare: 'Forskare',
  'Forskning kring barn och ungdomar': 'Forskning kring barn och ungdomar',
  Organisationer: 'Organisationer',
  'Barn med särskilda behov': 'Barn med särskilda behov',
  Övrigt: 'Övrigt',
  'Den sidan finns inte.': 'Den sidan finns inte.',
  'Vi kunde inte hitta sidan du letade efter. Kanske är adressen felstavad? Var god kontrollera stavningen och försök igen.':
    'Vi kunde inte hitta sidan du letade efter. Kanske är adressen felstavad? Var god kontrollera stavningen och försök igen.',
  'Skriv svar här': 'Skriv svar här',
  // zod translations
  Required: 'Obligatoriskt',
  'String must contain at least 1 character(s)': 'Obligatoriskt fält',
  'String must contain at least 20 character(s)': 'För kort. Minst 20 tecken',
  'String must contain at least 100 character(s)': 'För kort. Minst 100 tecken',
  'String must contain at least 200 character(s)': 'För kort. Minst 200 tecken',
  'String must contain at most 3400 character(s)': 'För långt. Max 3400 tecken',
  'not a valid email address': 'Ogiltig mejladress',
  "Invalid enum value. Expected 'EDUCATION_DEVELOPMENT' | 'UNIVERSITY_MUSIC_STUDENT' | 'CHILD_CARE_SUPPORT' | 'YOUTH_INTEGRATION_WORK' | 'CHILD_YOUTH_RESEARCH' | 'SUPPORT_NEEDY' | 'OTHER_QUESTIONS', received ''":
    'Ogiltigt ämne',
  "Invalid enum value. Expected 'MUSICIAN' | 'ORGANIZATION' | 'RESEARCHER' | 'OTHER', received ''":
    'Ogiltig kontotyp',
  'Name is required': 'Ogiltigt namn',
  'Invalid email': 'Ogiltig mejladress',
  'Year must be a whole number': 'Ogiltigt år',
  "Invalid enum value. Expected 'FALL' | 'SPRING', received ''":
    'Ogiltig termin',
  'Please select account type': 'Välj kontotyp',
  'String must contain at least 3 character(s)': 'För kort',
  'Invalid personal number format. Format is 19950101-1234':
    'Ogiltigt personnummer. Korrekt format: 19950101-1234',
  'Must be exactly 5 digits': 'Ogiligt postnummer. Korrekt format: 12345',
  'Invalid postal code format': 'Ogiltigt postnummer',
  'Research status must be at least 1 character long':
    'Forskarstatus måste vara minst 1 tecken långt',
  'Expected boolean, received string': 'Ogiltigt val',
  'Expected number, received string': 'Ogiltigt nummer',
  'Invalid project level': 'Ogiltigt projektnivå',
  "Invalid enum value. Expected 'LICENTIATE_PROJECT' | 'DOCTORAL_PROJECT' | 'POST_DOC_PROJECT' | 'PROJECT_OF_HIGH_SCIENTIFIC_LEVEL', received ''":
    'Ogiltigt projektnivå',
  "Invalid enum value. Expected 'MUSICIAN' | 'ORGANIZATION' | 'RESEARCHER', received ''":
    'Ogiltigt kontotyp',
  'Number must be greater than or equal to 1000':
    'Ogiltigt belopp. Ange minst 1000 kr',
  'Only numbers': 'Endast siffror',
  'Invalid organisation number format. Format is 123456-1234':
    'Ogiltigt organisationsnummer. Korrekt format: 123456-1234',
  'Invalid phone number. Format can include country code like +46701234567':
    'Ogiltigt telefonnummer. Korrekt format: +46701234567',
  'Invalid url': 'Ogiltig fil. Var god kontrollera att du ha bifogat en fil',
  'Nytt meddelande': 'Nytt meddelande',
  'Nytt meddelande från': 'Nytt meddelande från',
  'Namn: ': 'Namn: ',
  'Email: ': 'Email: ',
  'Meddelande: ': 'Meddelande: ',
  'Besök kc-fonden.se': 'Besök kc-fonden.se',
  Meny: 'Meny',
  id: 'ID',
  createdAt: 'Skapad (Datum)',
  updatedAt: 'Uppdaterad (Datum)',
  createdById: 'Skapad Av ID',
  updatedById: 'Uppdaterad Av ID',
  createdBy: 'Skapad Av',
  updatedBy: 'Uppdaterad Av',
  kind: 'Typ',
  emailAddress: 'E-postadress',
  name: 'Namn',
  address: 'Adress',
  postalCode: 'Postnummer',
  city: 'Stad',
  receivedGrantBefore: 'Tidigare Beviljat Bidrag',
  lastGrantYear: 'Senaste Bidragsår',
  requestedAmount: 'Begärt Belopp',
  bankAccountNumber: 'Bankkontonummer',
  bankAccountHolder: 'Kontoinnehavare',
  projectSummary: 'Projektsammanfattning',
  signedCertUrl: 'Signerat Certifikat URL',
  status: 'Status',
  grantedAmount: 'Tilldelat belopp',
  personalNumber: 'Personnummer',
  researchStatus: 'Forskningsstatus',
  institution: 'Institution',
  adminName: 'Administratörens Namn',
  adminPhone: 'Administratörens Telefon',
  projectLevel: 'Projektets Nivå',
  projectTitle: 'Projektets Titel',
  researchPlanUrl: 'Forskningsplan URL',
  recommendationUrl: 'Rekommendationsbrev URL',
  affiliationCertUrl: 'Intyg Om Affiliering URL',
  instrument: 'Instrument',
  currentEducation: 'Nuvarande Utbildning',
  soughtEducation: 'Eftersträvad Utbildning',
  educationSituation: 'Utbildningssituation',
  termAndYear: 'Termin och År',
  reference1Name: 'Referens 1 Namn',
  reference1Phone: 'Referens 1 Telefon',
  reference2Name: 'Referens 2 Namn',
  reference2Phone: 'Referens 2 Telefon',
  financialStatementUrl: 'Ekonomisk Redogörelse URL',
  personalLetterUrl: 'Personligt Brev URL',
  CSNCertUrl: 'CSN Intyg URL',
  admissionCertUrl: 'Antagningsbesked URL',
  orgName: 'Organisationsnamn',
  representativeName: 'Representantens Namn',
  orgNumber: 'Organisationsnummer',
  projectBudgetUrl: 'Projektbudget URL',
  'Övriga bilagor': 'Övriga bilagor',
  'Övriga bilagor (PDF eller Excel)': 'Övriga bilagor (PDF eller Excel)',
  'Behöver du bifoga övriga bilagor för att ge en fullgod ansökan, kan du bifoga de här.':
    'Behöver du bifoga övriga bilagor för att ge en fullgod ansökan, kan du bifoga de här.',
  annualReportUrl: 'Årsredovisning URL',
  activityReportUrl: 'Verksamhetsrapport URL',
  otherAttachmentsUrl: 'Övriga bilagor URL',
  otherAttachmentUrl: 'Övriga bilagor URL',
  bylawsUrl: 'Stadgar URL',
  acceptedTerms: 'Godkända Villkor',
  'You must accept the terms': 'Du måste godkänna vilkoren',
  grantYear: 'Beviljat År',
  grantSemester: 'Beviljat Termin',
  summary: 'Sammanfattning',
  attachments: 'Bilagor',
  timestamp: 'Tidsstämpel',
  visitorId: 'Besökares ID',
  pageVisited: 'Besökt Sida',
  userAgent: 'Användaragent',
  ipAddress: 'IP-Adress',
  country: 'Land',
  region: 'Region',
  referer: 'Referens',
  true: 'Ja',
  false: 'Nej',
  Avbryt: 'Avbryt',
  'Är du säker på att du vill skicka detta svar?':
    'Är du säker på att du vill skicka detta svar?',
  'Välkommen tillbaka': 'Välkommen tillbaka', // Welcome back
  "Det här är Stiftelsen Kempe-Carlgrenska Fonden's Dashboard, som ger insikter om våra användare och ansökningstrender.":
    "Det här är Stiftelsen Kempe-Carlgrenska Fonden's Dashboard, som ger insikter om våra användare och ansökningstrender.",
  'Besökare (månadsvis)': 'Besökare (månadsvis)',
  'Nya användare (månadsvis)': 'Nya användare (månadsvis)',
  'Meddelanden (månadsvis)': 'Meddelanden (månadsvis)',
  'Svarade meddelanden (månadsvis)': 'Svarade meddelanden (månadsvis)',
  'Ansökningar (totalt)': 'Ansökningar (totalt)',
  Månadsvis: 'Månadsvis',
  Årsvis: 'Årsvis',
  'Svaret måste vara minst 20 tecken': 'Svaret måste vara minst 20 tecken',
  //
} as const;

export default se;

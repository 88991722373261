import(/* webpackMode: "eager", webpackExports: ["Analytics"] */ "/home/runner/work/kc-fonden/kc-fonden/node_modules/.pnpm/@vercel+analytics@1.4.1_next@15.1.5_@babel+core@7.26.7_@opentelemetry+api@1.9.0_babel-plugin-_t7ssh7mdomfazijhudy4q6znfy/node_modules/@vercel/analytics/dist/react/index.mjs");
;
import(/* webpackMode: "eager", webpackExports: ["SpeedInsights"] */ "/home/runner/work/kc-fonden/kc-fonden/node_modules/.pnpm/@vercel+speed-insights@1.1.0_next@15.1.5_@babel+core@7.26.7_@opentelemetry+api@1.9.0_babel-pl_fvlfml7ho42w5l2efkc6vsd4xy/node_modules/@vercel/speed-insights/dist/next/index.mjs");
;
import(/* webpackMode: "eager", webpackExports: ["SessionProvider"] */ "/home/runner/work/kc-fonden/kc-fonden/node_modules/.pnpm/next-auth@5.0.0-beta.25_next@15.1.5_@babel+core@7.26.7_@opentelemetry+api@1.9.0_babel-plugin-_bnm555b43lat3wexmlu4ndbrye/node_modules/next-auth/react.js");
;
import(/* webpackMode: "eager" */ "/home/runner/work/kc-fonden/kc-fonden/node_modules/.pnpm/next@15.1.5_@babel+core@7.26.7_@opentelemetry+api@1.9.0_babel-plugin-react-compiler@0.0.0-exp_h2oj6jp3eaa7epnqubtw6zzmry/node_modules/next/font/google/target.css?{\"path\":\"src/app/layout.tsx\",\"import\":\"Inter\",\"arguments\":[{\"subsets\":[\"latin\"],\"variable\":\"--font-sans\"}],\"variableName\":\"fontSans\"}");
;
import(/* webpackMode: "eager", webpackExports: ["default"] */ "/home/runner/work/kc-fonden/kc-fonden/src/components/analytics/CookiePolicyProvider.tsx");
;
import(/* webpackMode: "eager", webpackExports: ["useTranslation","default"] */ "/home/runner/work/kc-fonden/kc-fonden/src/components/translate/TranslationProvider.tsx");
;
import(/* webpackMode: "eager", webpackExports: ["ShadcnThemeProvider"] */ "/home/runner/work/kc-fonden/kc-fonden/src/shadcn/theme-provider/provider.tsx");
;
import(/* webpackMode: "eager", webpackExports: ["Toaster"] */ "/home/runner/work/kc-fonden/kc-fonden/src/shadcn/ui/toaster.tsx");
;
import(/* webpackMode: "eager" */ "/home/runner/work/kc-fonden/kc-fonden/src/styles/globals.css");
;
import(/* webpackMode: "eager", webpackExports: ["TRPCReactProvider"] */ "/home/runner/work/kc-fonden/kc-fonden/src/trpc/react.tsx");

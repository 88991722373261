function path(root: string, sublink: string, lang: AppLocale) {
  return withLocale(`${root}${sublink}`, lang);
}

const withLocale = (path: string, lang: AppLocale) => `/${lang}${path}`;

const ROOTS_DASHBOARD = '/dashboard';
const ROOTS_AUTH = '/auth';
const ROOTS_ABOUT = '/grants';

// Now, all the paths will automatically include the locale

export const PATH_PAGE = {
  home: (lang: AppLocale) => path('/', '', lang),
  about: (lang: AppLocale) => path('/about', '', lang),
  contact: (lang: AppLocale) => path('/contact', '', lang),
  faqs: (lang: AppLocale) => path('/faqs', '', lang),
  page403: (lang: AppLocale) => path('/403', '', lang),
  page404: (lang: AppLocale) => path('/404', '', lang),
  page500: (lang: AppLocale) => path('/500', '', lang),
};

export const PATH_ABOUT = {
  root: (lang: AppLocale) => path(ROOTS_ABOUT, '', lang),
  education: (lang: AppLocale) => path(ROOTS_ABOUT, '/education', lang),
  students: (lang: AppLocale) => path(ROOTS_ABOUT, '/students', lang),
  childcare: (lang: AppLocale) => path(ROOTS_ABOUT, '/childcare', lang),
  integration: (lang: AppLocale) =>
    path(ROOTS_ABOUT, '/integration-work', lang),
  research: (lang: AppLocale) => path(ROOTS_ABOUT, '/research', lang),
  needful: (lang: AppLocale) => path(ROOTS_ABOUT, '/people-in-need', lang),
};

export const PATH_AUTH = {
  root: (lang: AppLocale) => path(ROOTS_AUTH, '', lang),
  adminLogin: (lang: AppLocale) => path(ROOTS_AUTH, '/admin-login', lang),
  login: (lang: AppLocale) => path(ROOTS_AUTH, '/login', lang),
  register: (lang: AppLocale) => path(ROOTS_AUTH, '/register', lang),
  newPassword: (lang: AppLocale) => path(ROOTS_AUTH, '/new-password', lang),
  reset: (lang: AppLocale) => path(ROOTS_AUTH, '/reset', lang),
  verify: (lang: AppLocale) => path(ROOTS_AUTH, '/verify', lang),
};

export const PATH_DASHBOARD = {
  root: (lang: AppLocale) => path(ROOTS_DASHBOARD, '', lang),
  analytics: (lang: AppLocale) => path(ROOTS_DASHBOARD, '/analytics', lang),
  applications: {
    root: (lang: AppLocale) => path(ROOTS_DASHBOARD, '/applications', lang),
    kind: (lang: AppLocale, kind: 'musician' | 'researcher' | 'organization') =>
      path(ROOTS_DASHBOARD, `/applications/${kind}`, lang),
    create: (lang: AppLocale) =>
      path(ROOTS_DASHBOARD, '/applications/create', lang),
    view: (
      kind: 'musician' | 'researcher' | 'organization',
      id: number,
      lang: AppLocale
    ) => path(ROOTS_DASHBOARD, `/applications/${kind}/${id}`, lang),
    edit: (kind: string, id: number, lang: AppLocale) =>
      path(ROOTS_DASHBOARD, `/applications/${kind}/${id}/edit`, lang),
  },
  reports: {
    root: (lang: AppLocale) => path(ROOTS_DASHBOARD, '/reports', lang),
    kind: (lang: AppLocale, kind: 'musician' | 'researcher' | 'organization') =>
      path(ROOTS_DASHBOARD, `/reports/${kind}`, lang),
    create: (lang: AppLocale) => path(ROOTS_DASHBOARD, '/reports/create', lang),
    view: (kind: string, id: number, lang: AppLocale) =>
      path(ROOTS_DASHBOARD, `/reports/${kind}/${id}`, lang),
    edit: (kind: string, id: number, lang: AppLocale) =>
      path(ROOTS_DASHBOARD, `/reports/${kind}/${id}/edit`, lang),
  },
  user: {
    root: (lang: AppLocale) => path(ROOTS_DASHBOARD, '/users', lang),
    me: (lang: AppLocale) => path(ROOTS_DASHBOARD, `/users/me`, lang),
    list: (lang: AppLocale) => path(ROOTS_DASHBOARD, '/users/list', lang),
    new: (lang: AppLocale) => path(ROOTS_DASHBOARD, '/users/new', lang),
    manage: (id: string, lang: AppLocale) =>
      path(ROOTS_DASHBOARD, `/users/${id}`, lang),
  },
  messages: {
    root: (lang: AppLocale) => path(ROOTS_DASHBOARD, '/messages', lang),
    inbox: {
      root: (lang: AppLocale) => path(ROOTS_DASHBOARD, '/messages/inbox', lang),
      message: (id: string, lang: AppLocale) =>
        path(ROOTS_DASHBOARD, `/messages/inbox/${id}`, lang),
    },
    sent: {
      root: (lang: AppLocale) => path(ROOTS_DASHBOARD, '/messages/sent', lang),
      message: (id: string, lang: AppLocale) =>
        path(ROOTS_DASHBOARD, `/messages/sent/${id}`, lang),
    },
  },
};
